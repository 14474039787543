import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {useConnect} from "../api/contracts";
import {Modal, Button, Radio, Select, Pagination, Form, Input, notification, message} from 'antd';
import MintImg from "../imgs/MintIMG.jpg"
import {getContractByContract, getContractByName} from "../api/connectContract";
import {dealMethod, viewMethod} from "../utils/contractUtil"
import {useNavigate} from "react-router-dom";

import BigNumber from "bignumber.js";
import judgeStatus from "../utils/judgeStatus";
import addressMap from "../api/addressMap";
import {showNum} from "../utils/bigNumberUtil";
import {USDTDecimals} from "../config/constants";
import {getTeamRecordOne, getTeamRecordTwo} from "../graph/donate";
import USDTIcon from "../imgs/usdt.png"
import {formatAddress} from "../utils/publicJs";
import {dealTime} from "../utils/timeUtil";
import add from "../imgs/add.png";
import cut from "../imgs/remove.png";
import { useTranslation } from 'react-i18next';

const usdtDecimal = 6
const MaxUint256 = BigNumber('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff').toFixed(0);

const CreatePage = (props) => {
    const [form] = Form.useForm();
    const history = useNavigate();
    const goPage = (url) => {
        history(url);
    }
    const {i18n } = useTranslation();
    const { t} = useTranslation();
    console.log(i18n)
    const CreatePage = styled.div`
      margin-top: 150px;
      color: #fff;

      .total-box {
        padding: 20px 0;
        justify-content: space-between;

        .item {
          padding: 20px;
          width: 48%;
          background: #1A1414;
          border-radius: 20px 20px 20px 20px;
          opacity: 1;
          border: 1px solid rgba(255, 255, 255, 0.1);

          strong {
            font-size: 20px;
          }
        }
      }

      .ant-form-item {
        .ant-form-item-row {
          display: flex !important;
          justify-content: space-between !important;
          padding-top: 10px;

          .ant-form-item-label {

            text-align: left;

            label {
              font-size: 20px;
            }
          }

          .input-box {
            width: 200px !important;

          }

          .ant-select-selector {
            color: #fff !important;
            font-weight: bold;
            width: 200px !important;
            background: #333;
            border: none;
          }

          .ant-select-arrow {
            color: #fff !important;
          }

          .ant-input {
            color: #fff !important;
            font-weight: bold;
            background: #333;

            &::selection {
              color: #4A52FF;
            }
          }

          .ant-form-item-control-input {
          }
        }
      }

      .ant-form-item-control-input {
        width: 200px !important;
        margin-left: auto;

      }

      .list-header {
        .col {
          flex-grow: 1;
          justify-content: center;
        }
      }

      .ant-form-item-control {
        display: flex;
        justify-content: flex-end;

      }

      .username {
        width: 200px;
      }

      .active-box {
        width: 610px;
        margin: 10px auto;
        font-size: 22px;

        .active-content {
          width: 120px;
          height: 36px;
          border-radius: 10px;
          border: 1px solid rgba(241, 210, 98, 0.3);
          text-align: center;
          line-height: 36px;

          &.active {
            color: #87be32;
          }

          &.regist {
            color: #1296be;
          }
        }
      }

      .create-box {
        width: 610px;
        background: #0E0D10;
        box-shadow: 0px 23px 15px 5px rgba(0, 0, 0, 0.6);
        border-radius: 20px 20px 20px 20px;
        border: 1px solid rgba(241, 210, 98, 0.3);
        margin: 0 auto;
        padding: 30px;

        .mint-img {
          width: 100%;

        }

        .price {
          display: flex;
          justify-content: space-between;
          padding-top: 10px;
          font-size: 20px;
        }

        .mint-btn {
          margin-top: 30px;
          border: none;
          width: 100%;
          height: 50px;
          background: #4A52FF;
          border-radius: 10px 10px 10px 10px;
          font-family: Roboto, Roboto;
          font-weight: bold;
          font-size: 18px;
          color: #FFFFFF;
        }

        .white-btn {
          margin-top: 30px;
          border: none;
          width: 100%;
          height: 50px;
          font-family: Roboto, Roboto;
          font-weight: bold;
          font-size: 18px;
          color: #FFFFFF;
          background: #272727;
          border-radius: 10px 10px 10px 10px;
          border: 1px solid rgba(255, 255, 255, 0.1);
        }
      }

      .my-nft {
        width: 80%;
        margin: 100px auto;
        background: rgb(14, 13, 16);
        box-shadow: 0px 23px 15px 5px rgba(0, 0, 0, 0.6);
        border-radius: 20px 20px 20px 20px;
        border: 1px solid rgba(241, 210, 98, 0.3);
        padding: 30px;

        .nft-list {
          display: grid;
          grid-template-columns: repeat(5, minmax(130px, 1fr));
          gap: 10px;
          margin: 50px 0;

          .nft-item {
            padding: 5%;
            border-radius: 5%;
            background: #0e0d10;

            img {
              width: 100%;
            }

            .id-box {
              display: flex;
              justify-content: space-between;
              padding: 5px 0;
              font-size: 18px;
            }
          }
        }

      }

      .myNftTitle {
        font-size: 26px;

      }
      @media (max-width: 1000px){
        .active-box {
          width: 100%;
          padding: 0 20px;
        }
      }
      @media screen and (max-width: 1000px) {
        .myNftTitle {
          font-size: 20px;
          padding-bottom: 20px;
        }

        .hasnotnft {
          font-size: 20px !important;
        }

        .my-nft {
          width: 90%;
          margin: 100px auto;

          .nft-list {
            display: grid;
            grid-template-columns: repeat(2, minmax(130px, 1fr));
            gap: 10px;
            margin: 50px 0;

            .nft-item {
              padding: 5%;
              border-radius: 5%;
              background: #0e0d10;

              img {
                width: 100%;
              }

              .id-box {
                display: flex;
                justify-content: space-between;
                padding: 5px 0;
                font-size: 18px;
              }
            }
          }

        }
      }
      @media screen and (max-width: 1000px) {
        .create-box {
          width: 90vw !important;


          .price {
            font-size: 16px;
          }

          .mint-btn {

          }

          .white-btn {

          }
        }
      }

    `
    let {state, dispatch} = useConnect();
    const [contract, setContract] = useState(null)
    const [whitelistArr, setWhiteListArr] = useState([])
    const [owner, setOwner] = useState("  ")
    const [allowance, setAllowance] = useState()
    const [isAdmin, setIsAdmin] = useState(false)
    const [isAdmin2, setIsAdmin2] = useState(false)
    const [isAdmin3, setIsAdmin3] = useState(false)
    const [isAdmin4, setIsAdmin4] = useState(false)

    const [usdtBalance, setUSDTBalance] = useState(0)
    const [isExist, setIsExist] = useState(false)

    const [price, setValue] = useState(0)

    const [totalUSDT, setTotalUSDT] = useState(0)
    const [totalFDAOPoint, setTotalFDAOPoint] = useState(0)

    const [pageCount, setPageCount] = useState(20)
    const [curPage, setCurPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [isShowWhite, setIsShowWhite] = useState(false)
    const [canMintAMount, setCanMintAMount] = useState(0)
    const [mintedMount, setCanMintedAMount] = useState(0)
    const [totalUsdtReward, setTotalUsdtReward] = useState(0)
    const [totalPointReward, setTotalPointReward] = useState(0)
    const [myNFT, setMyNFT] = useState([])

    const [selectValue, setSelectValue] = useState(1)

    const [optionsArr, setOptionsArr] = useState([{
        label: "1", value: 1
    }, {
        label: "2", value: 2
    }, {
        label: "3", value: 3
    }, {
        label: "4", value: 4
    }, {
        label: "5", value: 5
    }])
    const [teamArr1, setTeamArr1] = useState([])
    const [teamArr2, setTeamArr2] = useState([])

    const [initAmount, setInitAmount] = useState(0)
    const [totalMint, setTotalMint] = useState(0)
    const [userName, setUserName] = useState(undefined)
    const [discountedPrice, setDiscountedPrice] = useState()
    const [userRegisterStatus,setUserRegisterStatus] = useState(false)
    const [activeStatus,setActiveStatus] = useState()

    const [solName, setSolname] = useState(undefined)
    const [isW, setIsW] = useState(true)
    const [isShowActive,setIsShowActive] = useState(false)
    const openNotification = (message) => {
        notification.error({
            message: message,
            description:
                "",
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
    };
    const handleViewCoinMethod = async (name, params) => {
        let contractTemp = await getContractByName("usdt", state.api,)
        return viewMethod(contractTemp, state.account, name, params)
    }
    const handleDealCoinMethod = async (name, params) => {
        let contractTemp = await getContractByName("usdt", state.api,)
        return dealMethod(contractTemp, state.account, name, params)
    }
    const handleViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("MintSOL", state.api,)
        if (!contractTemp) {
            openNotification("Please connect")
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }
    const handleDealMethod2 = async (name, params) => {
        let contractTemp = await getContractByName("MintSOL", state.api,)
        if (!contractTemp) {
            openNotification("Please connect")
        }
        dealMethod(contractTemp, state.account, name, params)
    }
    const getUserMintIds = async () => {
        const res = await handleViewMethod("getUserMintIds", [state.account])
        setMyNFT(res)
    }
    const getUSDTBalance = async () => {
        const res = await handleViewCoinMethod("balanceOf", [state.account])
        setUSDTBalance(BigNumber(res).div(10 ** USDTDecimals).toFixed(0))
    }
    const getValue = async () => {
        const res = await handleViewMethod("value", [])
        setValue(res)
    }
    const getUserName = async () => {
        const res = await handleViewMethod("userInfo", [state.account])
        if (res && res.username) {
            setUserName(res.username)
        }
    }
    const getRegisterStatus = async () => {
        const res = await handleViewMethod("purchaseActivationStatus", [state.account])
        setUserRegisterStatus(res)
    }
    const getActivationStatus = async () => {
        const res = await handleViewMethod("activationStatus", [state.account])
        setActiveStatus(res)
    }
    const getAllowance = async () => {
        const res = await handleViewCoinMethod("allowance", [state.account, addressMap["MintSOL"].address])
        setAllowance(res)
    }
    const getOwner = async () => {
        const Admin = await handleViewMethod("owner", [])
        setOwner(Admin)
    }

    const approve = async () => {
        await handleDealCoinMethod("approve", [addressMap["MintSOL"].address, MaxUint256])
        getAllowance()
    }
    const openMessageError = (content) => {
        message.warn(content, 5)
    };
    const handleRegister = async ()=>{
        await handleDealMethod2("purchaseInvitationCode",[form.getFieldValue().activeCode])
        getRegisterStatus()
        setIsShowActive(false)
        setTimeout(()=>{
            getRegisterStatus()
        },2000)
    }
    const MintNFT = async () => {
        if (selectValue == 0) {
            openNotification("Please select amount")
        }
        if (BigNumber(selectValue).gt(canMintAMount - mintedMount)) {
            openNotification("Excess of the reissuable balance")
            return
        }
        // const username = userName ? userName : form.getFieldValue().userName
        await handleDealMethod2("mintFireOgNft", [selectValue])

        getAllowance()
        getWhiteListCanMintAmount()
        getAmount()
        getUSDTBalance()
        getActivationStatus()


        // getUserName()
        setTimeout(() => {
            getAllowance()
            getWhiteListCanMintAmount()
            getAmount()
            getUSDTBalance()
            getActivationStatus()
        }, 1000)


    }
    const onChangePage = async (page) => {
        await setCurPage(page)
    }
    const handleShowSizeChange = async (page, count) => {
        setPageCount(count)
    }
    const getAmount = async () => {
        const amount = await handleViewMethod("initAmount", [])
        setInitAmount(amount)
        const res = await handleViewMethod("totalMint", [])
        setTotalMint(res)
    }
    const getisAdmin = async () => {
        const Admin2 = await handleViewMethod("isNotL2Address", [state.account])
        const Admin3 = await handleViewMethod("isNotL3Address", [state.account])
        const Admin4 = await handleViewMethod("isNotL4Address", [state.account])

        setIsAdmin(Admin2 || Admin3 || Admin4)
        setIsAdmin3(Admin3)
        setIsAdmin2(Admin2)
        setIsAdmin4(Admin4)
    }
    const getWhiteListCanMintAmount = async () => {
        const res = await handleViewMethod("CanMintAmount", [])
        const res2 = await handleViewMethod("MintAmount", [state.account])
        // const l2 = await handleViewMethod("l2whiteListCanMintAmount", [])
        // const l3 = await handleViewMethod("l3whiteListCanMintAmount", [])

        setCanMintedAMount(res2)

        setCanMintAMount(res)
        let tempArr = []
        for (let i = 0; i < (res - res2); i++) {
            tempArr.push({
                label: i + 1,
                value: i + 1
            })
        }
        setOptionsArr(tempArr)
    }
    const getWiteList = async () => {
        let tempArr = [], whiteArr = []
        for (let i = 0; i < 7; i++) {
            whiteArr = await handleViewMethod("getWiteList", [])

            const inW1 = whiteArr.some(item => {
                return item.toLowerCase() == state.account.toLowerCase()
            })
            tempArr.push(inW1)

        }
        setWhiteListArr(whiteArr)
        setTotal(whiteArr.length)

    }
    let usernameExists = async (value) => {
        let contractTemp = await getContractByName("MintSOL", state.api,)
        if (!contractTemp) {
            openMessageError("Please connect")
        }
        return contractTemp.methods.usernameExists(value).call({
            from: state.account,
        })
    }
    const checkUserName = async (value, fn) => {
        if (!value) {
            return
        }
        let name = value ? value.toString().toLowerCase() : ""
        setSolname(value.toString().toLowerCase())
        const isExist = await usernameExists(name)
        if (!(/^[A-Za-z]+$/.test(value.substr(0, 1)))) {
            openMessageError("The first character of the user name must be a letter")
            return
        }
        if (isExist) {
            openMessageError("userName is exist")
            fn("userName is exist")
        } else {
            fn()
        }
        setIsExist(isExist)
        return isExist
    }
    const handleChange = (val) => {
        setSelectValue(val)
    }
    const getMyTeam = async () => {
        const res1 = await getTeamRecordOne(state.account)
        let tempUsdt = 0, tempPoint = 0

        if (res1 && res1.data) {
            const arr = res1.data.inviteRewards
            setTeamArr1(arr)
            for (let i = 0; i < arr.length; i++) {
                tempUsdt = BigNumber(tempUsdt).plus(arr[i].oneReward)
                tempPoint = BigNumber(tempPoint).plus(arr[i].onePoint)
            }
        }
        const res2 = await getTeamRecordTwo(state.account)
        if (res2 && res2.data) {
            const arr = res2.data.inviteRewards
            setTeamArr2(arr)
            for (let i = 0; i < arr.length; i++) {
                tempUsdt = BigNumber(tempUsdt).plus(arr[i].twoReward)
                tempPoint = BigNumber(tempPoint).plus(arr[i].twoPoint)
            }
        }
        setTotalUsdtReward(tempUsdt)
        setTotalPointReward(tempPoint)
    }
    useEffect(async () => {

        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }
        setMyNFT([])
        getOwner()
        getAllowance()
        getisAdmin()
        // getWiteList()
        getUserMintIds()
        getWhiteListCanMintAmount()
        getisAdmin()
        getAmount()
        getUSDTBalance()

        getValue()
        // getUserName()
        getMyTeam()
        getRegisterStatus()
        getActivationStatus()
    }, [state.account])

    useEffect(async () => {

        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }
        getOwner()
        getAllowance()
        getisAdmin()
        // getWiteList()

        // getWhiteListCanMintAmount()
        getisAdmin()
        getAmount()

    }, [isAdmin3, isAdmin2, isAdmin4])
    useEffect(async () => {
        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }

        if (selectValue > 0) {
            // getDiscountedPrice()
        }
    }, [selectValue])
    return (
        <CreatePage>
            <Modal className="" title="Register" open={isShowActive}
                   onOk={() => {
                       handleRegister()
                   }}
                   onCancel={() => {
                       setIsShowActive(false)
                   }}>
                <Form form={form} name="control-hooks" className="form">
                    <Form.Item
                        name="activeCode"
                        validateTrigger="onBlur"
                        label="Referral Address"
                        validateFirst={true}
                    >
                        <div className="input-box">
                            <Input type="text"></Input>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal className="nft-dialog" title="Whitelist" open={isShowWhite} footer={null} onCancel={() => {
                setIsShowWhite(false)
            }}>
                <div className='superdao-list-box up-list-box'>
                    <div className='list-header up-header'>
                        <div className='col'>
                            No.
                        </div>
                        <div className='col'>
                            Address
                        </div>
                    </div>
                    {whitelistArr.map((item, index) => {
                        return (index >= pageCount * (curPage - 1) && index < pageCount * curPage &&
                            <div className='list-item up-item' key={index}>
                                <div className='col no'>
                                    {index + 1}
                                </div>
                                <div className='col address'>
                                    <a style={{whiteSpace: "nowrap"}}>
                                        {item}
                                    </a>
                                </div>


                            </div>)
                    })}

                </div>
                <div className="pagination">

                    <Pagination current={curPage} showSizeChanger
                                onShowSizeChange={handleShowSizeChange}
                                onChange={onChangePage} total={total}
                                defaultPageSize={pageCount}/>

                </div>
            </Modal>
            <div className={"active-box "} style={{display:"flex"}}>
                <div className={"active-content "+(userRegisterStatus?"active":"")}>
                    {userRegisterStatus? t("Registered"):t("Unregistered")}

                </div>
                <div className={"active-content " +(activeStatus?"active":"")} style={{marginLeft:"10px"}}>
                    {activeStatus?t("Activated"):t("Non-activated")}
                </div>
            </div>
            <div className="create-box">
                <img className="mint-img" src={MintImg} alt=""/>
                <div className="price">
                    <span>
                       {t("Total amount")}
                    </span>
                    <span>
                        {initAmount}
                    </span>
                </div>
                <div className="price">
                    <span>
                        {t("Minted amount")}
                    </span>
                    <span>
                        {totalMint}
                    </span>
                </div>
                <div className="price">
                    <span>
                        {t("Remained")}
                    </span>
                    <span>
                        {BigNumber(initAmount).minus(totalMint) > 0 ? BigNumber(initAmount).minus(totalMint).toFixed(0) : 0}
                    </span>
                </div>
                <div className="price">
                    <span>
                        {t("You can mint amount")}
                    </span>
                    <span>
                        {(canMintAMount - mintedMount) > 0 ? (canMintAMount - mintedMount) : 0}
                    </span>
                </div>


                <div className="price">
                    <span>
                        {t("Price")}
                    </span>
                    <span>
                        {BigNumber(price).dividedBy(10 ** USDTDecimals).toFixed(0)} USDT
                    </span>
                </div>
                {/*<div className="price">*/}
                {/*    <span>*/}
                {/*       Discounted Price*/}
                {/*    </span>*/}
                {/*    <span>*/}
                {/*        {BigNumber(discountedPrice).dividedBy(10 ** USDTDecimals).toFixed(0)} USDT*/}
                {/*    </span>*/}
                {/*</div>*/}
                {isW ? <>
                        <Form form={form} name="control-hooks" className="form">
                            {/*{userName ? <Form.Item*/}
                            {/*    name="userName"*/}
                            {/*    label="Forum Username"*/}
                            {/*>*/}
                            {/*    <span style={{marginLeft: "10px"}}>{userName}</span>*/}
                            {/*</Form.Item> : <Form.Item*/}
                            {/*    name="userName"*/}
                            {/*    label="Forum Username"*/}
                            {/*    validateTrigger="onBlur"*/}
                            {/*    validateFirst={true}*/}
                            {/*    rules={[*/}
                            {/*        {required: true, message: 'Please input your username!'},*/}
                            {/*        {min: 4, message: "name length need > 4"},*/}
                            {/*        {max: 20, message: "name length need < 20"},*/}
                            {/*        {*/}
                            {/*            pattern: new RegExp('^[0-9a-zA-Z_]{1,}$', 'g'),*/}
                            {/*            message: 'The value can contain only digits, letters, and underscores'*/}
                            {/*        },*/}
                            {/*        {*/}
                            {/*            validator: (rule, value, fn) => {*/}
                            {/*                checkUserName(value, fn)*/}
                            {/*            }*/}
                            {/*        },*/}
                            {/*    ]}*/}

                            {/*>*/}
                            {/*    <Input*/}
                            {/*        className="username"*/}
                            {/*        allowClear*/}
                            {/*    />*/}
                            {/*</Form.Item>*/}
                            {/*}*/}
                            <Form.Item
                                name="amount"
                                validateTrigger="onBlur"
                                label={t("Amount")}
                                validateFirst={true}
                            >


                                <div className="input-box">
                                    <Select
                                        className="select-chain"
                                        defaultValue="Goerli network"
                                        onChange={handleChange}
                                        value={selectValue}
                                        options={optionsArr}
                                    />
                                </div>
                            </Form.Item>
                        </Form>
                        {userRegisterStatus?
                            BigNumber(allowance).lt(selectValue * 10 ** 18) ? <Button className="mint-btn" onClick={() => {
                                approve()
                            }}>
                                {t("Approve")}
                            </Button> : <Button className="mint-btn" onClick={() => {
                                MintNFT()
                            }}>{t("Mint")}</Button>

                            : <Button className="mint-btn" onClick={() => {
                                setIsShowActive(true)
                            }}> {t("Register")}</Button>
                        }

                    </>
                    : <div className="price">
                        Not in whitelist
                    </div>}

                <div className="price">
                    <span>
                        {t("Balance")}
                    </span>
                    <span>
                        {usdtBalance} USDT
                    </span>
                </div>
                {/*<Button className="white-btn" onClick={() => {*/}
                {/*    setIsShowWhite(true)*/}
                {/*}}>Whitelist</Button>*/}

                {owner.toLowerCase() == state.account.toLowerCase() &&
                    <Button className="mint-btn" onClick={() => {
                        goPage("/NFTAdmin")
                    }}>Level1 Admin</Button>}
                {isAdmin &&
                    <Button className="mint-btn" onClick={() => {
                        goPage("/Admin")
                    }}>{t("Admin")}</Button>}
            </div>
            <div className={"myNFT"}>

                <div className="my-nft ">
                    <div className="myNftTitle">{t("My NFT")}</div>
                    {myNFT.length == 0 && <div className="hasnotnft" style={{textAlign: "center", fontSize: "26px"}}>
                        There is no NFT yet
                    </div>}
                    <div className="nft-list">
                        {myNFT.map((item, index) => {
                            return (<div className="nft-item" key={index}>
                                <img src={MintImg}/>
                                <div className="id-box">
                                    <div className="left">ID</div>
                                    <div className="right">
                                        {item}
                                    </div>
                                </div>
                            </div>)
                        })}

                    </div>
                </div>
            </div>
            <div className="panel-box part2" style={{display:"none"}}>
                <div className="panel-container" style={{width: "80%"}}>
                    <div className="list-top-part">
                        <div className="panel-title">
                            {t("Team Income")}
                        </div>
                    </div>
                    <div className="flex-box total-box">
                        <div className="item">
                            <div className="name">USDT Total Income</div>
                            <strong> {showNum(BigNumber(totalUsdtReward).div(10 ** 18).toString())}</strong>
                        </div>
                        <div className="item">
                            <div className="name">FDAOPoint Total Income</div>
                            <strong> {showNum(totalPointReward)}</strong>
                        </div>
                    </div>
                    <div className="fire-list-box donate-list" style={{minWidth: '100%'}}>
                        <div className="list-header ">
                            <div className="col">
                                No.
                            </div>
                            <div className="col">
                                Level
                            </div>
                            <div className="col">
                                Address
                            </div>
                            <div className="col">
                                USDT Rewards
                            </div>
                            <div className="col">
                                Point Rewards
                            </div>
                            <div className="col">
                                Time
                            </div>
                        </div>

                        {
                            teamArr1.map((item, index) => (
                                index >= pageCount * (curPage - 1) && index < pageCount * curPage &&
                                <div className="list-item" key={index}>
                                    <div className="col no">
                                        {index + 1}
                                    </div>
                                    <div className="col">
                                        {1}
                                    </div>
                                    <div className="col">
                                        {formatAddress(item.user)}
                                    </div>

                                    <div className="col price">
                                        <img width={20} height={20} style={{marginRight: "3px"}} src={USDTIcon}
                                             alt=""/>
                                        {showNum(BigNumber(item.oneReward).div(10 ** 18))}
                                    </div>
                                    <div className="col price">
                                        <img width={20} height={20} style={{marginRight: "3px"}} src={USDTIcon}
                                             alt=""/>
                                        {showNum(BigNumber(item.onePoint))}
                                    </div>
                                    <div className="col">
                                        {dealTime(item.blockTimestamp)}
                                    </div>
                                </div>
                            ))
                        }
                        {
                            teamArr2.map((item, index) => (
                                index >= pageCount * (curPage - 1) && index < pageCount * curPage &&
                                <div className="list-item" key={index}>
                                    <div className="col no">
                                        {index + 1}
                                    </div>
                                    <div className="col">
                                        {2}
                                    </div>
                                    <div className="col">
                                        {formatAddress(item.user)}
                                    </div>

                                    <div className="col price">
                                        <img width={20} height={20} style={{marginRight: "3px"}} src={USDTIcon}
                                             alt=""/>
                                        {showNum(BigNumber(item.twoReward).div(10 ** 18))}
                                    </div>
                                    <div className="col price">
                                        <img width={20} height={20} style={{marginRight: "3px"}} src={USDTIcon}
                                             alt=""/>
                                        {showNum(BigNumber(item.twoPoint))}
                                    </div>
                                    <div className="col">
                                        {dealTime(item.blockTimestamp)}
                                    </div>
                                </div>
                            ))
                        }

                    </div>
                    {/*<div className="pagination">*/}
                    {/*    {*/}
                    {/*        <Pagination current={curPage} showSizeChanger*/}
                    {/*                    onShowSizeChange={handleShowSizeChange}*/}
                    {/*                    onChange={onChangePage} total={total}*/}
                    {/*                    defaultPageSize={pageCount}/>*/}
                    {/*    }*/}
                    {/*</div>*/}
                </div>
            </div>
        </CreatePage>
    )
}
export default CreatePage
